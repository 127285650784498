// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cla-index-jsx": () => import("./../src/pages/cla/index.jsx" /* webpackChunkName: "component---src-pages-cla-index-jsx" */),
  "component---src-pages-developers-components-developers-articles-developers-articles-jsx": () => import("./../src/pages/developers/components/DevelopersArticles/developersArticles.jsx" /* webpackChunkName: "component---src-pages-developers-components-developers-articles-developers-articles-jsx" */),
  "component---src-pages-developers-components-onboarding-card-onboarding-card-jsx": () => import("./../src/pages/developers/components/OnboardingCard/onboardingCard.jsx" /* webpackChunkName: "component---src-pages-developers-components-onboarding-card-onboarding-card-jsx" */),
  "component---src-pages-developers-index-jsx": () => import("./../src/pages/developers/index.jsx" /* webpackChunkName: "component---src-pages-developers-index-jsx" */),
  "component---src-pages-guides-index-jsx": () => import("./../src/pages/guides/index.jsx" /* webpackChunkName: "component---src-pages-guides-index-jsx" */),
  "component---src-pages-guides-tile-jsx": () => import("./../src/pages/guides/tile.jsx" /* webpackChunkName: "component---src-pages-guides-tile-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-index-jsx": () => import("./../src/pages/integrations/index.jsx" /* webpackChunkName: "component---src-pages-integrations-index-jsx" */),
  "component---src-pages-style-comp-index-jsx": () => import("./../src/pages/style-comp/index.jsx" /* webpackChunkName: "component---src-pages-style-comp-index-jsx" */),
  "component---src-pages-tile-jsx": () => import("./../src/pages/tile.jsx" /* webpackChunkName: "component---src-pages-tile-jsx" */),
  "component---src-templates-integrations-template-jsx": () => import("./../src/templates/IntegrationsTemplate.jsx" /* webpackChunkName: "component---src-templates-integrations-template-jsx" */),
  "component---src-templates-markdown-template-jsx": () => import("./../src/templates/MarkdownTemplate.jsx" /* webpackChunkName: "component---src-templates-markdown-template-jsx" */)
}

